<template>
  <b-container class="registration-card-container">
    <template>
      <b-card class="register-card mb-3">
        <!-- <b-row class="pl-3 pr-3 text-center">
          <div class="w-100">
            <Steps
              :stepsArray="stepsArray"
              :config="config"
              :length="config.length"
            />
          </div>
        </b-row>
         -->
        <b-container v-for="(step, stepIndex) of config" :key="stepIndex">
          
          <div
            :key="stepIndex"
            :title="step.title"
            v-if="stepIndex === currentStep"
          >
            <small v-if="!noButtons" class="text-muted">Step {{ currentStep + 1 }} of {{ config.length }}</small>
            <hr v-if="!noButtons" />
            <h1 class="mb-0">{{ step.title }}</h1>
            
            <b-row class="mt-4 mb-4">
              <template v-for="(el, elementIndex) of step.elements">
                <b-col
                  :key="elementIndex"
                  :cols="el.sizes.xs"
                  :sm="el.sizes.sm"
                  :md="el.sizes.md"
                  :lg="el.sizes.lg"
                >
                  <b-form-group :label="el.label">
                    <b-form-input
                      :name="el.label"
                      v-if="el.type == 'text'"
                      :ref="el.ref"
                      :type="el.type"
                      v-validate="el.rules"
                      v-model="value[el.field]"
                    ></b-form-input>
                    <b-form-textarea
                      :name="el.label"
                      v-if="el.type == 'textarea'"
                      :ref="el.ref"
                      :type="el.type"
                      v-validate="el.rules"
                      v-model="value[el.field]"
                    ></b-form-textarea>
                    <b-form-checkbox-group
                      :name="el.label"
                      v-if="el.type === 'checkbox'"
                      :ref="el.ref"
                      v-model="value[el.field]"
                      v-validate="el.rules"
                      :options="el.values"
                      stacked
                    ></b-form-checkbox-group>
                    <b-form-radio-group
                      :name="el.label"
                      :ref="el.ref"
                      v-if="el.type === 'radio'"
                      v-model="value[el.field]"
                      v-validate="el.rules"
                      :options="el.values"
                      stacked
                    ></b-form-radio-group>
                    <b-form-select
                      :name="el.label"
                      :ref="el.ref"
                      v-if="el.type === 'dropdown'"
                      v-model="value[el.field]"
                      v-validate="el.rules"
                      :options="el.values"
                    ></b-form-select>
                    <b-form-datepicker
                      :name="el.label"
                      :ref="el.ref"
                      v-if="el.type === 'date' || el.type === 'datetime'"
                      v-model="value[el.field]"
                      v-validate="el.rules"
                    ></b-form-datepicker>
                    <ImageUpload 
                      v-if="el.type == 'image'" 
                      :url="value[el.field]" 
                      v-validate="el.rules" 
                      ref="image"
                      @updateImage="updateImage(el.field)"
                    />
                    <div v-if="submitted && errors.has(el.label)" class="alert-danger">
                      {{ errors.first(el.label) }}
                    </div>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
          </div>
        </b-container>
        <b-button-toolbar v-if="!noButtons" justify>
          <b-button
            variant="primary"
            @click="previousStep"
            :class="previousButtonClass"
          >
            Previous
          </b-button>

          <b-button v-if="isLastStep" @click="doSubmit">Submit</b-button>
          <b-button variant="primary" v-else @click="nextStep">Next</b-button>
        </b-button-toolbar>
      </b-card>
    </template>
  </b-container>
</template>
<script>
import Vue from "vue";
import { translateSetting } from "@/utils/translated-setting.util";
import Steps from "@/components/Steps.vue";
import ImageUpload from "@/components/admin/ImageUpload.vue" 

export default {
  props: ["config", "value", "noButtons", "register", "submitted"],
  components: {
    // Steps,
    ImageUpload
  },
  data() {
    return {
      currentStep: 0,
      elementTypes: [
        "text",
        "email",
        "password",
        "number",
        "checkbox",
        "radio",
        "dropdown",
        "date",
        "datetime",
      ],
      stepsArray: [],
      elementTypesWithOptions: ["checkbox", "radio", "dropdown"],
      showEdit: false,
      isValid: true,
    };
  },
  created() {
    this.initializeSteps();
  },
  methods: {
    updateImage(field, newUrl) {
      this.value[field] = newUrl
    },
    needsOptions(type) {
      return this.elementTypesWithOptions.includes(type);
    },
    translate(value) {
      return translateSetting(value, this.locale);
    },
    nextStep() {
      this.currentStep++;
      Vue.set(this.stepsArray, this.currentStep, "active");
    },
    previousStep() {
      Vue.set(this.stepsArray, this.currentStep, "inactive");
      this.currentStep--;
      Vue.set(this.stepsArray, this.currentStep, "active");
    },
    doSubmit() {
      this.$emit("submit", this.value);
    },
    initializeSteps() {
      for (var i = 0; i < this.config.length; i++) {
        if (i == 0) {
          this.stepsArray[i] = "active";
        } else {
          this.stepsArray[i] = "inactive";
        }
      }
    },
  },
  computed: {
    previousButtonClass() {
      if (this.isFirstStep) {
        return "invisible";
      }
      return "";
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.config.length - 1;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.registration-card-container {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.register-card {
  width: 100%;
  // max-width: 30rem;
  margin: 0 auto;
  vertical-align: center;
  // color: $text-color-header;
  // background-color: $color-primary;
}

.submit-button-container {
  padding-top: 10px;
}

.link-privacy {
  color: $text-color-title;
}
</style>
